$(function() {
  $(".js-fill-text").click(function(e) {
    e.preventDefault();
    var textDestination = $(this).data('text-destination');
    var selector = $(this).data('selector');
    var currentText = $("." + textDestination).val();

    if (currentText.length === 0) {
      $("." + textDestination).val($(this).data('text-value'));
    } else {
      $("." + textDestination).val(currentText + ", " + $(this).data('text-value'));
    }

    $(this).hide();
    $("." + selector).show(400);
  });
});
