$(function() {
  // Turns btn-group into a form element
  // Define:
  //   * a data 'destination-id' on the btn-group which is the form element id to alter
  //   * a data 'selection-value' on the <a> tags which represent the selection choice
  $('.js-btn-group-form-element a').click(function (e) {
    var btn_group_element = $(this).closest('.js-btn-group-form-element');
    var destination_id = btn_group_element.data('destination-id');
    var selection_choice = $(this).data('selection-value');
    $('#' + destination_id).val(selection_choice);
    $('#' + destination_id).change();

    btn_group_element.find('a').removeClass('active');
    btn_group_element.find('button').removeClass('active');
    $(this).addClass("active");

    e.preventDefault();
  });

  // Changes btn-group dropdown-toggle text to the selected item
  $('.js-btn-group-form-element .js-btn-toggle a').click(function (e) {
    var btn_group_element = $(this).closest('.js-btn-group-form-element');
    btn_group_element.find('a').removeClass('active');
    btn_group_element.find('button').removeClass('active');

    var btn_toggle = btn_group_element.find(".dropdown-toggle");

    if(btn_toggle !== undefined){
      btn_toggle.addClass("active");
      var selected_name = $(this).html();
      btn_toggle.html(selected_name + " <span class='caret'></span>");
    }
  });
});
