import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import Structure from "../../lib/Structure";
import StructureActions from "./StructureActions";
import InspectionFormStructureNode from "./InspectionFormStructureNode";
import {
  selectStructure,
  fetchRelationshipsForStructure,
  setTopLevelStructure,
} from "./store/actions/structuresActions";

class StructureNodeBase extends React.Component {
  static propTypes = {
    // Objects to present
    structure: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    inspectionFormStructures: PropTypes.array.isRequired,

    // Current state
    isSelected: PropTypes.bool,

    // Actions
    fetchRelationshipsForStructure: PropTypes.func.isRequired,
    handleSelectStructure: PropTypes.func.isRequired,
    handleRefocusStructure: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.isSelected,
      sortedChildren: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // If it is being selected and it isn't expanded, then expand
    if (
      this.props.isSelected === true &&
      prevProps.isSelected === false &&
      prevState.isExpanded === false
    ) {
      this.setState({ ...this.state, isExpanded: true });
    }

    if (prevProps.children !== this.props.children) {
      this.setState({
        sortedChildren: Structure.sortByName(this.props.children),
      });
    }
  }

  toggleExpansion() {
    this.setState({ ...this.state, isExpanded: !this.state.isExpanded });
  }

  // Actions
  nameSelected = (e) => {
    e.preventDefault();
    if (e.altKey) {
      this.props.handleRefocusStructure(this.props.structure.id);
    } else {
      this.props.handleSelectStructure(this.props.structure.id);
      if (this.props.isSelected) {
        this.toggleExpansion();
      }
    }
  };

  handleArrowClick = () => {
    if (this.state.isExpanded === false) {
      this.props.handleSelectStructure(this.props.structure.id);
    }
    this.toggleExpansion();
  };

  fetchRelationships = () => {
    this.props.fetchRelationshipsForStructure(this.props.structure);
  };

  renderList() {
    if (!this.state.isExpanded) {
      return null;
    }

    if (this.props.children.length > 0) {
      return this.state.sortedChildren.map((child) => (
        <li key={child.id}>
          <StructureNode structureId={child.id} />
        </li>
      ));
    }

    if (this.props.inspectionFormStructures.length > 0) {
      return this.props.inspectionFormStructures.map((ifs) => (
        <li key={ifs.id}>
          <InspectionFormStructureNode inspectionFormStructure={ifs} />
        </li>
      ));
    }
    if (this.props.structure.isLoadingRelationships) {
      // If we don't have anything to show yet, but it is loading,
      // we want to show the user something.
      return (
        <li>
          <span className="fal fa-sync"></span> Loading
        </li>
      );
    }
  }

  render() {
    if (!this.props.structure) {
      return null;
    }
    const isLoading = this.props.structure.isLoadingRelationships;
    const hasChildren = this.props.structure.active_children_count > 0;
    const hasInspectionForms = this.props.inspectionFormStructures.length > 0;
    const cxLoad = {
      "fal fa-sync": isLoading,
      "fas fa-angle-down":
        (hasChildren || hasInspectionForms) && this.state.isExpanded,
      "fas fa-angle-right":
        (hasChildren || hasInspectionForms) && !this.state.isExpanded,
      "fas fa-folder-open": !hasChildren && !hasInspectionForms,
    };
    const iconClass = cx(cxLoad);
    return (
      <div
        structure-id={this.props.structure.id}
        onMouseOver={this.fetchRelationships}
      >
        <p
          className={
            "AreaTree__node__name" + (this.props.isSelected ? " active" : "")
          }
        >
          <span className={iconClass} onClick={this.handleArrowClick}></span>
          <span className="left-padding" onClick={this.nameSelected}>
            {this.props.structure.fullName}
          </span>
        </p>

        {this.state.isExpanded && this.props.isSelected && (
          <StructureActions structure={this.props.structure} />
        )}

        <ul>{this.renderList()}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const structure = state.structures.structuresById[ownProps.structureId];
  return {
    structure: structure,
    isSelected: structure && state.structures.state.selectedId === structure.id,
    children: structure ? [...structure.children(state)] : [],
    inspectionFormStructures: structure
      ? structure.inspectionFormStructures(state)
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSelectStructure: (id) => {
      dispatch(selectStructure(id));
    },
    handleRefocusStructure: (id) => {
      dispatch(setTopLevelStructure(id));
    },
    fetchRelationshipsForStructure: (structure) => {
      if (
        structure.hasLoadedRelationships === false &&
        structure.isLoadingRelationships === false
      ) {
        dispatch(fetchRelationshipsForStructure(structure));
      }
    },
  };
};

const StructureNode = connect(
  mapStateToProps,
  mapDispatchToProps
)(StructureNodeBase);

export default StructureNode;
