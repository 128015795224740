import { fetchAndPagePromise as fetchAndPage } from "../../../../lib/Utilities";
/*
 * Action types
 */

export const FETCHING_INSPECTION_FORMS = "FETCHING_INSPECTION_FORMS"
export const FETCHED_INSPECTION_FORMS = "FETCHED_INSPECTION_FORMS"

/*
 * Action creators
 */

export function fetchingInspectionForms() {
  return {
    type: FETCHING_INSPECTION_FORMS
  }
}

export function fetchedInspectionForms(forms) {
  return {
    type: FETCHED_INSPECTION_FORMS,
    objects: forms
  }
}

function shouldGetInspectionForms(state) {
  // TODO: Not really a global state area since we scope by structureId
  return state.inspectionForms.state.isLoaded === false
}

export function fetchInspectionFormsForStructure(structureId) {
  return function (dispatch, getState) {
    if (shouldGetInspectionForms(getState())) {
      return dispatch(getInspectionForms(structureId))
    }
  }
}

// Async
function getInspectionForms(structureId) {
  return dispatch => {
    dispatch(fetchingInspectionForms())

    let uri = `/api/v4/inspection_forms.json`
    // TODO: Handle errors
    return fetchAndPage(uri, { structure_id: structureId }, "inspection_forms")
      .then(forms => dispatch(fetchedInspectionForms(forms)))
  }
}
