$(function() {
  // Default for all select2
  $(".select2").select2({
    allowClear: true,
    width: 'resolve'
  });

  $(".select2-filter").select2({
    allowClear: true
  });

  // Select2 with rounded dropdown
  $(".select2-links-dropdown").select2({
    width: '100%',
    dropdownAutoWidth: true
  }).on("select2-open", function() {
    $(".select2-drop").addClass("select2-bordered-dropdown");
  }).change(function () {
    window.location.href = $(this).val();
  });

  $(".select2-multiple").select2({
    multiple: true
  });
});
