$(function() {
  // Allow form to toggle between enabled/show and disabled/hide elements
  // need group-a and group-b tags
  $(".js-disable-hide").click(function(e) {
    e.preventDefault();

    var show_selector = "." + $(this).data('selector');
    var hide_selector = $(this);

    // handle case where the hide elements are defined
    if ($(this).data('hide-selector') !== undefined) {
      hide_selector = "." + $(this).data('hide-selector');
    }

    // hide and disable items
    $(hide_selector).addClass("hide").find(':input').prop('disabled', true);

    // toggle to show and enable elements in selector
    $(show_selector).removeClass("hide").find(":input").prop('disabled', false);
  });

  $(".js-show-hide").each(function(index, element){
    if ($(this).attr('data-hidden-text')){
      $(this).data('visible-text', $(this).html());
    } else {
      $(this).data('hidden-text', $(this).html());
    }
  });

  $(".js-show-hide").on('update-text', function() {
    var selector = "." + $(this).data('selector');
    var hiddenText = $(this).data('hidden-text');
    var visibleText = $(this).data('visible-text');

    if ($(selector).is(":visible")) {
      $(this).html(visibleText);
    }
    else {
      $(this).html(hiddenText);
    }
  });

  $('body').on('click', ".js-show-hide", function() {
    var selector = $(this).data('selector');
    $("." + selector).slideToggle(400, function() {
      $(".js-show-hide[data-selector='"+selector+"']").trigger('update-text');
    });
    return false;
  });
});
