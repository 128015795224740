$(function() {
  $(".js-submit-on-change select").change(function(e) {
    $(".loading").show();
    $(this).parents("form").submit();
  });

  $(".js-submit-on-change input").change(function(e) {
    $(".loading").show();
    $(this).parents("form").submit();
  });
});
