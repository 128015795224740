// Add Category to inspection forms page
window.add_category = function(html) {
  $(".remove_categories").show().removeClass('hidden');

  if ($(".inspection_form_elements .category").length > 0) {
    $(".inspection_form_elements").append(html);
  } else {
    $(".inspection_form_elements").prepend($(html).addClass("first"));
  }
};
