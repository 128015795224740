$(function() {
  // Flip text
  // Ability to have text be toggled in place
  $(".js-flip-text").each(function(index, element) {
    $(this).data('visible-text', $(this).html());
    $(this).data('visible', true);
  });

  $(".js-flip-text").click(function(e) {
    e.preventDefault();

    var isVisible = $(this).data('visible');
    if (isVisible){
      $(this).html($(this).data('hidden-text'));
    }
    else {
      $(this).html($(this).data('visible-text'));
    }
    $(this).data('visible', !isVisible);
  });
});
