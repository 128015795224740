$(function() {
  // Form Section Toggle
  //
  // Add 'class' and 'data-section' attributes to any radio inputs,
  // and class='form-section' to any toggle-able sections.
  //
  // Ex.
  //
  //    <input type="radio" class="form-section-toggle" data-section=".sectionA">
  //    <input type="radio" class="form-section-toggle" data-section=".sectionB">
  //
  //    <div class="form-section sectionA"></div>
  //    <div class="form-section sectionB"></div>
  //
  $("input[type='radio'].form-section-toggle").each(function(index, radio) {
    var $radio = $(radio)

    $radio.change(function() {
      var sectionSelector = $(this).data("section")

      disable(".form-section")
      enable(sectionSelector)
    })

    if($radio.attr("checked"))
      enable($radio.data("section"))

    function disable(selector) {
      $(selector).hide()
      $(selector).find("input, select, textarea").attr("disabled", true)
    }

    function enable(selector) {
      $(selector).show()
      $(selector).find("input, select, textarea").removeAttr("disabled")
    }
  })
});
