$(function() {
  // Users Notification Editing/Dependency
  // Can view inspections
  // - Can perform
  // - Can view detailed locations
  // - Can view private inspections
  // - Any inspection emails
  // Can access tickets
  // - Can create tickets
  // - Can view unassigned tickets
  // - Can change ticket status
  // - Any ticket emails
  // No emails - disable all checkboxes
  // Can view unassigned tickets
  // - [email] Unassigned ticket is created
  //
  // Users Notification Editing
  // Goal is to disable/gray out dependent sections when it's dependency is unchecked
  // Also to enable them when it is checked again
  // In order to reduce the number of additional fields in the html, before submission,
  // enable any dependent-disabled fields so that their values will properly be submitted

  ////
  // Manage all dependencies
  // Put a unique class on the checkbox that something is dependent on
  // Then on that dependent checkbox put `js-dependent` class and a
  // `data-dependent` attribute which contains the unique class(es) you set up (comma seperated).
  $('.js-dependent').on('checkDependency', function() {
    var dependencyClasses = $(this).data('dependent').split(",");
    var isDisabled = false;

    $.each(dependencyClasses, function(i, className) {
      // Get value of checkbox for dependency
      var isChecked = $("."+className).prop('checked');
      // It becomes disabled if one of them isn't checked
      isDisabled = isDisabled || !isChecked;
    });

    // Update disabled
    $(this).prop('disabled', isDisabled);
    // Update the parent label's class
    $(this).parent('label').toggleClass('text-muted', isDisabled);
    // Only if it is disabled do we want to uncheck it.
    // Leave it unchecked the other way.
    if (isDisabled) { $(this).prop('checked', false); }
  });
  // Make each dependency trigger `checkDependency`
  $('.js-dependent').each(function(i, item) {
    var dependent = item;
    // Grab all the dependencies
    var dependencyClasses = $(item).data('dependent').split(",");
    // Loop through each and set them up to trigger `checkDependency`
    $.each(dependencyClasses, function(i, className) {
      $("."+className).on('change', function() {
        $(dependent).trigger('checkDependency');
      });
    });
  });
  // When page load, make sure everything is up to date
  $('.js-dependent').trigger('checkDependency');

  // Before the form is submitted, make sure everything everything is enabled
  $('.js-dependent').parents('form').on('submit', function() {
    $('.js-dependent').prop('disabled', false);
  });
});
