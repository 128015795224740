import moment from "moment"

$(function() {
  $(".datepicker").datepicker({
    dateFormat: 'yy-mm-dd'
  });

  $(".js-datepicker").datepicker({
    dateFormat: 'yy-mm-dd'
  });

  $(".js-single-datepicker").daterangepicker({
    singleDatePicker: true,
    timePicker: false,
    format: 'YYYY-MM-DD'
  });

  $(".js-range-datepicker").each(function() {
    var datePicker   = $(this)
    var rangeOptions = {
      default: {
        'Today': [moment(), moment()],
        'This week': [moment().startOf('week'), moment().endOf('week')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      future: {
        'This week': [moment().startOf('week'), moment().endOf('week')],
        'Next week': [moment().startOf('week').add(1, "week"), moment().endOf('week').add(1, "week")],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'Next month': [moment().add(1, "month").startOf('month'), moment().add(1, "month").endOf('month')]
      }
    }

    var option = datePicker.data("ranges")
    var ranges = rangeOptions[option] || rangeOptions.default

    // Supports Anytime option by not enforcing a default start and end date.
    // This is needed to prevent the picker from trying to init nil dates.
    var datePickerOptions = {
      default: {
        format: 'YYYY-MM-DD',
        startDate: moment($(".js-range-datepicker").data('start-date')),
        endDate: moment($(".js-range-datepicker").data('end-date')),
        ranges: ranges,
        locale: {
          customRangeLabel: 'Custom',
          daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'],
          firstDay: 1
        }
      },
      anytime: {
        ranges: rangeOptions.default
      }
    }

    var datePickerParams = datePickerOptions[option] || datePickerOptions.default;

    datePicker.daterangepicker(datePickerParams, function(start, end, label) {
      // Set hidden date range filters
      $(".js-range-datepicker").parent().find(".js-start-date-filter").val(start.format('YYYY-MM-DD'));
      $(".js-range-datepicker").parent().find(".js-end-date-filter").val(end.format('YYYY-MM-DD'));

      // Set link name to new date range
      $(".js-range-datepicker").html(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
    });
  })

  $('.js-range-datepicker').on('apply.daterangepicker', function(ev, picker) {
    $(".js-submit-on-change select").change();
  });

  // Anytime datepicker button. Clears date back to anytime.
  $('.js-range-datepicker-anytime').on('click', function(ev, picker) {
    $(this).parent().find(".js-start-date-filter").val("");
    $(this).parent().find(".js-end-date-filter").val("");
    $(".js-range-datepicker").html("Anytime");
    $(".js-submit-on-change select").change();
  });
});
