$(function() {
  $('.js-ticket-assignee').on('ajax:success', function(event, xhr, status, error) {
    var assigneeName = xhr['ticket']['assignee'];
    if (assigneeName != null) {
      $(".js-ticket-assignee-label").html(assigneeName['name']);
    } else {
      $(".js-ticket-assignee-label").html("No one");
    }

    // hide error message if user tried another selection
    $(this).find(".alert-danger").hide();

    // close modal
    $("#assign-ticket-modal").modal("hide");

    // Hide previous successful alerts so they don't stack up
    $(".js-alert-box .alert-success").hide();

    // copy success alert and display message
    $(".js-alert-box .alert-success").first().clone().show().appendTo(".js-alert-box").find(".js-alert-message").html("The ticket's assignment has been updated.");

  });

  $('.js-ticket-assignee').on('ajax:error', function(event, xhr, status, error) {
    $(this).find(".alert-danger").show();
  });

  $('.js-ticket-category').on('ajax:success', function(event, xhr, status, error) {
    var categoryName = xhr['ticket_category']['name'];
    $(this).find('.js-ticket-category-label').html(categoryName).effect("highlight", {color: '#5cb85c'}, 1000);
    $(this).parent().parent().find('.js-show-hide').click();
  });

  $('.js-ticket-category').on('ajax:error', function(event, xhr, status, error) {
    $(this).find('.js-ticket-category-label').effect("highlight", {color: 'red'}, 2000);
  });
});
