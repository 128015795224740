import { Rest, fetchAndPagePromise as fetchAndPage } from "../../../../lib/Utilities";
/*
 * Action types
 */
export const DELETE_INSPECTION_FORM_STRUCTURE = "DELETE_INSPECTION_FORM_STRUCTURE"
export const SELECT_INSPECTION_FORM_STRUCTURE = "SELECT_INSPECTION_FORM_STRUCTURE"
export const ADD_INSPECTION_FORMS_STRUCTURES = "ADD_INSPECTION_FORMS_STRUCTURES"

/*
 * Action creators
 */

export function selectInspectionFormStructure(id) {
  return {
    type: SELECT_INSPECTION_FORM_STRUCTURE,
    id: id,
  }
}

export function deleteInspectionFormStructureCompleted(id, structureId) {
  return {
    type: DELETE_INSPECTION_FORM_STRUCTURE,
    inspectionFormStructureId: id,
    structureId: structureId,
  }
}

export function addInspectionFormsStructures(ifs) {
  return {
    type: ADD_INSPECTION_FORMS_STRUCTURES,
    objects: ifs,
  }
}


// Async
//
export function createInspectionFormsStructure(inspectionFormId, structureId) {
  return dispatch => {
    const params = {
      inspection_forms_structures: {
        structure_id: structureId,
        inspection_form_id: inspectionFormId,
      }
    }

    const uri = "/api/v4/inspection_forms_structures.json"
    return Rest.post(uri, params).then(response => {
      const ifs = response.inspection_forms_structures
      dispatch(addInspectionFormsStructures([ifs], structureId))
    })
  }
}

export function loadInspectionFormStructuresForStructure(structureId) {
  return dispatch => {
    // Let structure know it is getting new data
    dispatch(fetchingDataForStructure(structureId))
    const uri = `/api/v4/structures/${ structureId }/inspection_forms_structures`
    return fetchAndPage(uri, {}, "inspection_forms_structures").then(ifs => {
      dispatch(addInspectionFormsStructures(ifs, structureId))
    })
  }
}

export function deleteInspectionFormStructure(id, structureId) {
  return dispatch => {
    const uri = `/api/v4/inspection_forms_structures/${ id }.json`
    return Rest.delete(uri).then(() => {
      dispatch(deleteInspectionFormStructureCompleted(id, structureId))
    })
  }
}
