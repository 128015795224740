$(function() {
  $('.btn-auto-disable').click(function() {
    // show spinner to make sure the user knows an action is taking place
    $(".loading").show();

    // Disable button from double clicks and submit form as usual
    $(this).prop('disabled', true);
    $(this).parents('form').submit();
  });
});
