$(function() {
  // Reports / Feed
  // Async load inspection modal window
  function asyncLoadInspectionModal(selector) {
    $('.loading').show();

    // async url endpoint
    var modal_contents_url = $(selector).attr('data-remote-href');

    $.ajax({
      url: modal_contents_url,
      dataType: "script",
      success: function() {
        $('.loading').hide();
      }
    });

    // Change address bar to inspection url
    window.history.replaceState("Inspection", "Inspection", $(selector).data('history-href'));
  }


  // Async load modals when clicked
  // Also needed for table rows added in infinite scroll
  $('.js-infinite-scroll').on('click', '.js-async-modal', function() { asyncLoadInspectionModal(this) });

  // Used on links to display the link to an inspection as a modal
  // Requires template modal code to be present on the page.
  $('.js-inspection-modal').on('click', function(e) {
    e.preventDefault();
    asyncLoadInspectionModal(this);
  });

  // Direct link to inspection
  if($('.js-show-inspection-on-load').size() > 0) {
    asyncLoadInspectionModal('.js-show-inspection-on-load');
  }

  // Revert to the initial reports url when the modal is closed
  if($('.inspection-modal-lg').size() > 0) {
    var initial_history_path = window.location.pathname;
    $('.inspection-modal-lg').on('hide.bs.modal', function (e) {
      window.history.replaceState("Inspection", "Inspection", initial_history_path);
    });
  }

  // Infinite scroll
  if($(".js-infinite-scroll").size() > 0) {
    var isLoading = false;

    $(window).scroll(function(event) {
      $('.pagination').hide();
      var more_inspections_url = $('.pagination a.next_page').attr('href');

      if (!isLoading && more_inspections_url !== undefined && $(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
        isLoading = true;
        $.ajax({
          url: more_inspections_url,
          dataType: "script",
          success: function() {
            isLoading = false;
          }
        });
      }
    });
  }
});
