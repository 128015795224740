import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as API from "../../../lib/API";

export default function LocationPreview({ form, target, selected }) {
  const [count, setCount] = useState(0);
  const [formName, setFormName] = useState(null);
  const formElem = document.querySelector(form);
  const [optionsSelected, setOptionsSelected] = useState([...formElem.selectedOptions]);
  const label = count.toString();
  const labelEntity =
    count === 1 ? "scheduled inspection" : "scheduled inspections";

  const refresh = () => {
    const idsSelected = optionsSelected.map(option => option.value);

    API.inspectionSchedulesPreviewCount(selected, idsSelected).then(
      (newCount) => {
        if (newCount !== -1) {
          const formName = optionsSelected.length ? optionsSelected.map(option => option.textContent).join(", ") : null;
          setFormName(formName);
          setCount(newCount);
        } else {
          setFormName(null);
        }
      }
    );
  };

  useEffect(() => {
    $(form).change(() => {
      setOptionsSelected([...formElem.selectedOptions]);
    });
  }, []);

  useEffect(() => {
    refresh();
  }, [selected, optionsSelected]);

  if (selected.length <= 0 || optionsSelected.length <= 0) return null;

  return ReactDOM.createPortal(
    <div className="LocationPreview">
      <div>
        {count > 100 && (
          <div className="alert alert-danger">
            Warning! This will create a lot of {labelEntity}. Please be sure
            this is your intention.
          </div>
        )}
        {count > 0 && (
          <div className="alert alert-success">
            {label} {labelEntity} will be created by your selection.
          </div>
        )}

        {count <= 0 && (
          <div className="alert alert-warning">
            {label} areas found for {formName}
          </div>
        )}
      </div>
    </div>,
    document.querySelector(target)
  );
}
