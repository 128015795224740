$(function() {
  // Web Inspections
  $(".js-not-applicable").on("click", function(e) {
    e.preventDefault();
    var lineItemId = $(this).data("line-item-id");
    var lineItem = $("#" + lineItemId);

    if (lineItem.hasClass("disabled")) {
      // noop - not supported. Could re-enable the n/a line item
      lineItem.removeClass("disabled");
      lineItem.find('input, textarea, button, select').attr("disabled", false);
    } else if (confirm($(this).data("confirm-message"))) {
      lineItem.addClass("disabled");
      lineItem.find('input, textarea, button, select').attr("disabled", true);
    }
  });
});
