// Removing form elements
window.remove_fields = function(link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".line-item").
  hide(). // remove from user's view
    appendTo(".js-form-items"); // move so the css highlighting works
};

window.add_fields = function(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  $(".js-form-items").append(content.replace(regexp, new_id));
};
