$(function() {
  // Updates table's cached values when clicked (i.e. when triggering a sort)
  // When values are loaded in asynchronously, the table needs to be aware
  // of the new values to sort
  $(".js-table-sortable thead").click(function() {
    $(this).parent(".js-table-sortable").trigger("update");
  });

  // Default sortable table
  $(".js-table-sortable").tablesorter({
    theme : "bootstrap",
    widthFixed: true,
    ignoreCase: true,
    headerTemplate : '{content} {icon}',
    widgets : [ "uitheme", "filter", "zebra" ],

    widgetOptions : {
      zebra : ["even", "odd"],
      filter_columnFilters: false,
    }
  });
});
