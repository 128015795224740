$(function() {
  // Setup / Schedules
  // When the frequency_type changes then change the js-* divs to show the right pane
  // and disable the inputs in the other option as a safety for form submission
  $('#frequency_type').change(function() {
    // grab the current value
    var val = $("#frequency_type option:selected").val();

    // Hide both weekly and monthly options at first
    $(".js-weekly-options").slideUp();
    $(".js-monthly-options").slideUp();

    // Display the correct weekly or monthly pane
    // Set hidden value for the actual schedule frequency
    if (val === "0"){
      $('#schedule_freq').val('0');
      display_weekly_schedule_options();
    }
    else if (val === "1") {
      $('#schedule_freq').val('1');
      display_monthly_by_week_day_schedule_options();
      $("#monthlyDayOfWeekRadio").prop("checked", true);
    }
  });

  $("input[name=monthlyRadios]:radio").change(function () {
    var radioFrequencyChoice = $(this).val();
    $('#schedule_freq').val(radioFrequencyChoice);

    if (radioFrequencyChoice === '1') {
      // Day of Week
      // disable all inputs for Day of Month
      $(".js-monthly-month-day-options :input").attr("disabled", true);
      // enable all inputs for Day of Week
      $(".js-monthly-week-day-options :input").attr("disabled", false);
    }
    else if (radioFrequencyChoice === '2') {
      // Day of Month
      // disable all inputs for Day of Week
      $(".js-monthly-week-day-options :input").attr("disabled", true);
      // enable all inputs for Day of Month
      $(".js-monthly-month-day-options :input").attr("disabled", false);
    }
  });

  // On load need to trigger it to so that it shows the right section
  $("#schedule_days_of_week_offset").trigger('change');

  function display_weekly_schedule_options() {
    $(".js-weekly-options :input").attr("disabled", false);
    $(".js-monthly-options :input").attr('disabled', true);

    $(".js-weekly-options").prependTo('.js-options');
    $(".js-weekly-options").slideDown();
  };

  function display_monthly_by_week_day_schedule_options() {
    $(".js-monthly-options :input").attr("disabled", false);
    $(".js-monthly-month-day-options :input").attr("disabled", true);
    $(".js-weekly-options :input").attr("disabled", true);

    $(".js-monthly-options").prependTo('.js-options');
    $(".js-monthly-options").slideDown();
  };

  function initialize_schedules_setup() {
    var frequency_type = $('#schedule_freq').val();

    // Hide both weekly and monthly options at first
    $(".js-weekly-options").slideUp();
    $(".js-monthly-options").slideUp();

    if (frequency_type === "0") {
      display_weekly_schedule_options();
    } else if (frequency_type === "1") {
      display_monthly_by_week_day_schedule_options();
    } else if (frequency_type === "2") {
      // Monthly by date was chosen
      $(".js-monthly-options :input").attr("disabled", false);
      $(".js-monthly-week-day-options :input").attr("disabled", true);
      $(".js-weekly-options :input").attr("disabled", true);

      $(".js-monthly-options").prependTo('.js-options');
      $(".js-monthly-options").slideDown();
    }
  };
  initialize_schedules_setup();

  // On submit, remove the div unless it is selected
  $('.js-schedule-form').submit(function(){
    var val =  $("#schedule_freq").val();
    if (val != "0") {
      $(".js-weekly-options").remove(); }
    if (val != "1") {
      $(".js-monthly-week-day-options").remove(); }
    if (val != "2") {
      $(".js-monthly-month-day-options").remove(); }
  });
});
